
.blue-gradient-card {
    background: linear-gradient(-45deg, #2261b3, #227fb4, #255799, #008cff);
    background-size: 400% 400%;
    animation: blue-gradient 10s ease infinite;
}

@keyframes blue-gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.gray-gradient-card {
    background: linear-gradient(-90deg, #e9e7e7, #ffffff, #fcfcfc, #fbfdff);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

